const Defaults = Object.freeze({
  video: {
    INITIAL_WIDTH: 160,
    MAX_WIDTH: 400,
    MIN_WIDTH: 100,
    LARGE_WIDTH: 360,
    MEDIUM_WIDTH: 200,
    SMALL_WIDTH: 160,
    WIDTH_INCR: 20,
    RATIO: (9 / 16),
    BORDER_SIZE: 1,
    iconSize: {
      xs: 'lg',
      sm: '2x',
      md: '3x',
      lg: '4x'
    },
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '18px',
      lg: '22px'
    },
    poster: 'https://cdn.signly.co/images/transparent-poster.png'
  },
  window: {
    LARGE_WIDTH: 768
  },
  menu: {
    SMALL_BUTTON_WIDTH: 90,
    MEDIUM_BUTTON_WIDTH: 120,
    BUTTON_OFFSET: 4,
    height: {
      xs: '22px',
      sm: '32px',
      md: '48px',
      lg: '64px'
    },
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '18px',
      lg: '22px'
    },
    iconSize: {
      xs: 'lg',
      sm: '2x',
      md: '3x',
      lg: '4x'
    }
  },
  logo: {
    height: {
      xs: '36px',
      sm: '48px',
      md: '60px',
      lg: '72px'
    }
  },
  header: {
    height: {
      xs: '16px',
      sm: '26px',
      md: '44px',
      lg: '60px'
    },
    logoHeight: {
      xs: '14px',
      sm: '22px',
      md: '30px',
      lg: '50px'
    },
    logoWidth: {
      xs: '36px',
      sm: '54px',
      md: '66px',
      lg: '110px'
    },
    buttonSize: {
      xs: '14px',
      sm: '24px',
      md: '34px',
      lg: '50px'
    },
    iconSize: {
      xs: 'sm',
      sm: 'lg',
      md: 'xl',
      lg: '2xl'
    }
  },
  alerts: [
    {
      type: 'notTranslated',
      icon: 'sign-language'
    },
    {
      type: 'inTranslation'
    },
    {
      type: 'translated'
    },
    {
      type: 'requestSent'
    },
    {
      type: 'serverError'
    }
  ],
  images: {
    baseUrl: 'https://cdn.signly.co/images/'
  },
  textSegment: {
    playButtonWidth: 26,
    borderWidth: 3,
    minContrastRatio: 3,
    signIconSize: 14,
    signIconSizeDecrease: 6,
    signIconLimit: 20,
    borderLightColor: '#c9c9c9',
    borderDarkColor: '#333333'
  },
  website: {
    baseUrl: 'https://signly.co'
  },
  signlyServer: {
    baseUrl: 'https://signly.azurewebsites.net/api/v1/public'
  }
})

export default Defaults
