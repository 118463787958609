import i18n from './i18n'
const Defaults = require('./libs/defaults').default

let signlyApp = null
// eslint-disable-next-line no-unused-vars
let DragDropTouch = null

const Vue = require('vue').default
const SignlyApp = require('./SignlyApp.vue').default

const library = require('@fortawesome/fontawesome-svg-core').library
const faMinus = require('@fortawesome/free-solid-svg-icons').faMinus
const faPause = require('@fortawesome/free-solid-svg-icons').faPause
const faPlay = require('@fortawesome/free-solid-svg-icons').faPlay
const faPlus = require('@fortawesome/free-solid-svg-icons').faPlus
const faSpinner = require('@fortawesome/free-solid-svg-icons').faSpinner
const faSignLanguage = require('@fortawesome/free-solid-svg-icons').faSignLanguage
const faSyncAlt = require('@fortawesome/free-solid-svg-icons').faSyncAlt
const faTimes = require('@fortawesome/free-solid-svg-icons').faTimes

const FontAwesomeIcon = require('@fortawesome/vue-fontawesome').FontAwesomeIcon

require('./style/index.scss')

library.add(
  faMinus,
  faPause,
  faPlay,
  faPlus,
  faSignLanguage,
  faSpinner,
  faSyncAlt,
  faTimes
)

Vue.component('fa', FontAwesomeIcon)

Vue.config.productionTip = false

const bodyElement = document.getElementsByTagName('body')[0]

const signlyButtonElement = document.createElement('DIV')
const signlyBorderElement = document.createElement('DIV')
const signlyPlayingBorderElement = document.createElement('DIV')
const signlyAppElement = document.createElement('DIV')

signlyButtonElement.setAttribute('id', 'signly-button')
signlyBorderElement.setAttribute('id', 'signly-border')
signlyPlayingBorderElement.setAttribute('id', 'signly-playing-border')

signlyButtonElement.appendChild(createSignIcon())
bodyElement.appendChild(signlyButtonElement)
bodyElement.appendChild(signlyBorderElement)
bodyElement.appendChild(signlyPlayingBorderElement)
bodyElement.appendChild(signlyAppElement)

signlyApp = new Vue({
  i18n,
  render: h => h(SignlyApp)
}).$mount(signlyAppElement)

function createSignIcon () {
  const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  const gElement = document.createElementNS('http://www.w3.org/2000/svg', 'g')
  const pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path')

  pathElement.setAttribute('id', 'sign-icon-path')
  pathElement.setAttribute('d', 'M52.524,23.925L12.507,0.824c-1.907-1.1-4.376-1.097-6.276,0C4.293,1.94,3.088,4.025,3.088,6.264v46.205 c0,2.24,1.204,4.325,3.131,5.435c0.953,0.555,2.042,0.848,3.149,0.848c1.104,0,2.192-0.292,3.141-0.843l40.017-23.103 c1.936-1.119,3.138-3.203,3.138-5.439C55.663,27.134,54.462,25.05,52.524,23.925z M49.524,29.612L9.504,52.716 c-0.082,0.047-0.18,0.052-0.279-0.005c-0.084-0.049-0.137-0.142-0.137-0.242V6.263c0-0.1,0.052-0.192,0.14-0.243 c0.042-0.025,0.09-0.038,0.139-0.038c0.051,0,0.099,0.013,0.142,0.038l40.01,23.098c0.089,0.052,0.145,0.147,0.145,0.249 C49.663,29.47,49.611,29.561,49.524,29.612z')
  pathElement.setAttribute('fill', Defaults.textSegment.borderLightColor)

  svgElement.setAttribute('id', 'sign-icon')
  svgElement.setAttribute('viewBox', '0 0 58.752 58.752')
  svgElement.setAttribute('height', Defaults.textSegment.signIconSize)
  svgElement.setAttribute('width', Defaults.textSegment.signIconSize)

  gElement.appendChild(pathElement)
  svgElement.appendChild(gElement)

  return svgElement
}

function resetSignlyData () {
  window.localStorage.removeItem('signlyVideoWidth')
}

function turnOnSignly () {
  if (!window.localStorage.getItem('showSignly')) {
    resetSignlyData()
  }

  window.localStorage.setItem('showSignly', 'true')
  window.location.reload()
}

function turnOffSignly () {
  if (!window.localStorage.getItem('showSignly')) {
    resetSignlyData()
  }

  window.localStorage.setItem('showSignly', 'false')
  window.location.reload()
}

async function addSignlyGlobalFunctions () {
  window.turnOnSignly = turnOnSignly
  window.turnOffSignly = turnOffSignly

  const hasDataWfPage = document.documentElement.hasAttribute('data-wf-page')

  if (!hasDataWfPage) {
    DragDropTouch = await import('drag-drop-touch')
  }
}

const signlyOnButton = document.querySelector('#signly--1')
const signlyOffButton = document.querySelector('#signly--2')

if (signlyOnButton && signlyOffButton) {
  if (window.localStorage.getItem('showSignly') === 'true') {
    signlyOnButton.checked = true
  } else {
    signlyOffButton.checked = true
  }

  signlyOnButton.addEventListener('change', turnOnSignly)
  signlyOffButton.addEventListener('change', turnOffSignly)
}

window.addEventListener('load', addSignlyGlobalFunctions, { once: true })

window.onunload = function () {
  if (signlyOnButton && signlyOffButton) {
    signlyOnButton.removeEventListener('change', turnOnSignly)
    signlyOffButton.removeEventListener('change', turnOffSignly)
  }
}

export default signlyApp
